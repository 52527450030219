/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'spinner';
@import 'utils';
@import 'rtl';
@import 'responsive';

@media print {
    .breadcrumb {
        display: none !important;
    }
}

.color-success {
    color: green;
}

.color-primary {
    color: blue;
}

.color-secondary {
    color: grey;
}

.color-warning {
    color: yellow;
}

.color-danger {
    color: red;
}

.custom-toolbar {
    height: 50px;
    background-color: #461364;
    border-radius: 5px;
    color: white;

    display: flex;
    align-items: center;
    padding: 0 10px;
}

/* You can add global styles to this file, and also import other style files */
/* *{
    font-family: C, sans-serif;
} */


.ngx-pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #BA0021 !important;
}

.btn-primary {
    background-color: #BA0021!important;
    text-transform: capitalize !important;
    font-size: 15px;
}


.nav-tabs>.nav-item>.nav-link {
    color: #070707;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 15px;

}

.nav-tabs>.nav-item>.nav-link:hover {
    background-color: transparent;
}

.nav-tabs>.nav-item>.nav-link.active {
    background-color: #BA0021;
    color: #FFFFFF;
}

.card {
    border-radius: 12px;
    box-shadow: 0 10px 10px 6px rgba(0, 0, 0, 0.15);
}

table tbody tr td {
    width: 20%;
    text-align: left !important;
}

.nav-tabs {
    border: 0;
}


.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: #BA0021 !important;
}

.btn-primary:focus,
.btn-primary.active,
.btn-primary:active:focus {
    background-color: #BA0021 !important;
}


.fa {
    cursor: pointer;
}

.label-red {
    color: red;
    font-size: 20px;
}

.text-black {
    text-transform: capitalize;
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
}

.p-dropdown {
    width: 100%
}

.p-inputtext {
    padding: 0.38rem 0.75rem !important;
}

.invalid-feedback {
    font-size: 90% !important;
}

.text-validation{
    font-size: 90% !important;
    color: #dc3545;
}

.document-body {
    height: 80vh;
    overflow-y: scroll;
}

/* You can add global styles to this file, and also import other style files */
.bg-light {
    background: #BA0021 !important;
    color: #000;
}

.btn-primary {
    background-color: #BA0021 !important;
    border-color: #BA0021 !important;
}

.thead-secondary {
    background-color: #BA0021 !important;
}

.prduct-table {
    background-color: white
}

.table-card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px 5px;
    background-color: white;
}

table tbody tr td {
    width: 20%;
    text-align: center;
}

table thead tr th {
    width: 20%;
    text-align: center;
}

.pagination-custom {
    border-top: 1px solid rgb(219, 219, 219);
}

.dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #BA0021;
}

.custom-hyperlink {
    text-decoration: none;
    color: #000000;
    font-weight: 600;
    padding: 6px;
    border: none;
    background-color: #9585876e;
    border-radius: 5px;
    cursor: pointer;
}

.custom-hyperlink:hover {
   
    color: #000000;
    background-color: #9585876e;
    
}

.image-hyperlink {
    text-decoration: none;
    color: rgb(6, 50, 196);
    font-weight: 600;
    cursor: pointer;
}

.statusopen{
    color: green;
    font-weight: bold;
}

.statusclosed{
    color: red;
    font-weight: bold;
}

.logout-button{
    position: fixed;
    cursor: pointer;
    bottom: 50px;
    color: #999;
    width: inherit;
   
}

.field-disabled {
    background-color: #ced4da;
    cursor: no-drop;
}


input[type=radio] {
    margin-top: 10px !important;
    width: 17px;
    height: 17px;
}


.table-responsive::-webkit-scrollbar
{
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
	background-color: #eee8e8;
	border: none;
}

table.autowidth td{
    white-space:nowrap;
}


.custom-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  
}


.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col,
.gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}

.h-100 {
    height: 100% !important;
}

.shadow-none {
    box-shadow: none !important;
}

.hmt-card {
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.form-control {
    border-radius: 3px;
    border: 1px solid #999999;
}


.hmt-works {
    text-align: center;
    color: #BA0021;
}

.hmt-parent {
    font-size: 18px;
    font-weight: 500;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: black;
    margin-top: 10px;
}

.hmt-upload {
    box-shadow: 0;
    border: 0px none #ffffff;
    ;
}

.hmt-notes {
    height: 150px;
}

.parentForm .col-md-6 {
    margin-bottom: 15px;
}

.form-control.is-invalid {
    border: 1px solid #BA0021
}

.ml-5 {
    margin-left: 37px;
}

.status-true,
.status-completed {
    color: green;
    font-size: 15px;
}

.status-false,
.status-hold {
    color: red;
    font-size: 15px;
}

.table .thead-dark tr th,
.table tbody tr td {
    text-align: center !important;
}

.thead-dark {
   background-color: #9585876e;
}

.table .thead-dark tr th{
    padding: 10px;
}

table th {
    width: auto !important;
}

.modeofTeachingLabel{
    margin-top: 7px;
}

/* media */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px)and (max-width: 991px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.no-box-shadow {
    box-shadow: none;
}